@import '../../variables.scss';

.Header {
  background-color: $white;
  margin: 0;
  height: 80px;
  z-index: 10;
  
  button {
    all: unset;
  }
  
  .header-nav {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1280px;
  }
}
