@import '../../variables.scss';

.modal {
  padding: 30px;
  .modal-content {
    border-radius: 25px;
  }
}

.screen-size {
  text-align: center;
  
  @media print {
    display: none;
  }

  h1 {
    color: $tertiary-color;
  }
  
  p {
    margin-top: 30px;
    margin-bottom: 0;
  }

  .close {
    width: 100%;
    background-color: #D63F15;
    border-radius: 40px;
    color: #fff;
    font-family: "Fredoka One", cursive;
    text-align: center;
    text-transform: uppercase;
    padding: .25rem;
  }
}
