@import '../../variables.scss';

.back-to-home {
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .branding {
    height: 56px;
  }
}
